<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4"
          v-model="params.start_date" type="date" />
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4" v-model="params.end_date"
          type="date" />
        <v-btn class="mr-4" color="primary" @click="select()">Pesquisar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="6">
        <CompanySelect class="mr-4" :item-value="(item) => item.id" v-model="params.company_id" solo flat
          background-color="grey lighten-4" :dense="false" @change="select()" clearable />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total de Compras
              <br />
              <b class="text-h4">{{ $format.decimal(purchaseReport.sum_value) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total produtos s/desconto
              <br />
              <b class="text-h4">{{ $format.decimal(purchaseProductReport.sum_value) }}</b>

            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total de compras c/desconto
              <br />
              <b class="text-h4">{{ $format.decimal(purchaseProductReport.sum_net_total) }}</b>

            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card color="grey lighten-4" elevation="0" class="mt-6">
          <v-card-text>
            Ranking de Fornecedores
            <template v-for="item in purchaseBySupplierReport">
              <div class="my-4">
                <div class="d-flex justify-space-between align-center mb-1">
                  <div>
                    {{ item.supplier_name }}
                  </div>
                  <div class="text-right">
                    <h3 class="mt-3 text-primary font-weight-regular">
                      {{ $format.decimal(item.sum_value) }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col>
        <v-card color="grey lighten-4" elevation="0" class="mt-6">
          <v-card-text>
            Ranking de Categoria
            <template v-for="item in purchaseCategoryProductReport.data">
              <div class="my-4">
                <div class="d-flex justify-space-between align-center mb-1">
                  <div>
                    {{ item.category_name }}
                  </div>
                  <div class="text-right">
                    <h3 class="mt-3 text-primary font-weight-regular">
                      {{ $format.decimal(item.sum_value) }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { format } from 'date-fns';
import CompanySelect from "@/components/company/ui/CompanySelect.vue";

export default {
  components: {
    CompanySelect,
  },

  data: () => ({
    params: {
      company_id: null,
      start_date: format(new Date(), 'yyyy-MM-01'),
      end_date: format(new Date(), 'yyyy-MM-dd'),
    },
    purchaseReport: {},
    purchaseBySupplierReport: {},
    purchaseProductReport: {},
    purchaseCategoryProductReport: {},
  }),

  mounted() {
    this.select()
  },

  methods: {
    select() {
      this.getPurchaseReport()

      this.getPurchaseBySupplierReport()

      this.getPurchaseProductReport()

      this.getPurchaseCategoryProductReport()
    },

    getPurchaseReport() {
      return this.$http.index('purchase/purchase-report', this.params).then((response) => {
        this.purchaseReport = response.purchaseReport
      })
        .catch((error) => { });
    },

    getPurchaseBySupplierReport() {
      return this.$http.index('purchase/purchase-by-supplier-report', this.params).then((response) => {
        this.purchaseBySupplierReport = response.purchaseBySupplierReport.data
      })
        .catch((error) => { });
    },
    getPurchaseProductReport() {
      return this.$http.index('purchase/purchase-product-report', this.params).then((response) => {
        this.purchaseProductReport = response.purchaseProductReport
      })
        .catch((error) => { });
    },
    getPurchaseCategoryProductReport() {
      return this.$http.index('purchase/purchase-category-product-report', this.params).then((response) => {
        this.purchaseCategoryProductReport = response.purchaseCategoryProductReport
      })
        .catch((error) => { });
    }
  }
}
</script>

<style lang="scss" scoped></style></template>